import { apiClient } from "../core/apiClient";
import {
  AttachedEmail,
  DetachedEmail,
  Identifier,
  IdentifierResponse,
} from "types/profile";
import {ICounts} from 'types/profile';

export async function fetchIdentifiers(): Promise<Identifier[]> {
  const response = (await apiClient.get("/api/v1/profile"))
    .data as IdentifierResponse;

  return [
    ...response.authInfo,
    ...response.contactsInfo,
    // ...response.pendingConfirmation.map((i) => ({ ...i, confirmed: false })),
  ];
}

export async function fetchAmount(): Promise<ICounts> {
  const response = (await apiClient.get("/api/v1/profile/info"))
    .data as ICounts;

  return response;
}

export async function attachEmailData(data: AttachedEmail): Promise<void> {
  const { extraMeta } = data;
  if (extraMeta) data.extraMeta = btoa(extraMeta);
  await apiClient.post("/api/v1/auth/extra_contact/attach", {
    contact: data?.email,
    receiptExpirationDate: data?.receiptExpirationDate,
  });
}

export async function detachEmailData({
  identifierLogin,
  identifierType,
  identifierId,
}: DetachedEmail): Promise<void> {
  await apiClient.delete(`/api/v1/auth/extra_contact/unattach/${identifierId}`);
}

export async function detachPhoneData({
  identifierLogin,
  identifierType,
  identifierId,
}: DetachedEmail): Promise<void> {
  await apiClient.delete(`/api/v1/auth/extra_contact/unattach/${identifierId}`);
}
export async function deletePendingEmail({
  identifierLogin,
  identifierType,
  identifierId,
}: DetachedEmail): Promise<void> {
  await apiClient.delete(`/api/v1/auth/extra_contact/unattach/${identifierId}`);
}

export async function verifyEmail({
  query,
}: {
  query: string;
}): Promise<{
  status: string;
  extraMeta: string;
  redirectOnConfirmationLink: string;
  externalSystemType: number;
}> {
  return (
    await apiClient.post("/api/v1/auth/extra_contact/verify", { code: query })
  ).data;
}

export async function addPhone({
  phone,
}: // receiptExpirationDate,
// token,
{
  phone: string;
  // receiptExpirationDate: string;
  // token: string;
}): Promise<{ phone: string; codeExpiredIn: string }> {
  return (
    await apiClient.post("/api/v1/auth/extra_contact/attach", {
      contact: phone,
      // receiptExpirationDate,
      // captchaToken: token,
    })
  ).data;
}

export async function verifyPhone({
  phone,
  code,
  receiptExpirationDate,
}: {
  phone: string;
  code: string;
  receiptExpirationDate: string;
}): Promise<{ status: string }> {
  return (
    await apiClient.put("/api/v1/auth/extra_contact/verify", {
      contact: phone,
      code,
      receiptExpirationDate,
    })
  ).data;
}

export function BlockUser() {
  return apiClient.post("/api/v1/user/block", {});
}

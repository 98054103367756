import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Dialog } from "../../../ui-kit/Dialog";
import { ReactComponent as CloseIcon } from "../../../../assets/svg/ic-close.svg";
import {
  CloseDialogIcon,
  DialogHeaderTitle,
  DialogButtonsRow,
} from "../../../ui-kit/Dialog/styled";
import { Header, Time, Message } from "./styled";
import { markNotificationAsRead } from "../../../../api";
import { Notification } from "../../../../types/notification";
import { formatDate } from "../../../../utils/date";
import { Button } from "../../../ui-kit/Button";

interface Props {
  notification: Notification;
  onDismiss: Function;
}

export function NotificationModal({ notification, onDismiss }: Props) {
  useEffect(() => {
    markNotificationAsRead(notification.id);
  }, [notification.id]);

  return (
    <Dialog onDismiss={onDismiss} ariaLabel="notification-modal">
      <Header>
        <DialogHeaderTitle>{notification.subject}</DialogHeaderTitle>
        <CloseDialogIcon source={CloseIcon} onClick={() => onDismiss()} />
      </Header>
      <Time>
        {formatDate(new Date(notification.createdAt), "dd.MM.yyyy HH:mm")}
      </Time>
      <Message>
        <ReactMarkdown>{notification.sentMessage}</ReactMarkdown>
      </Message>
      {/* {notification.type === "BINDING" && (
        <DialogButtonsRow>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              e.currentTarget.getElementsByTagName("a")[0].click();
            }}
          >
            <Link to={`/partners?&partnerCode=${notification.id}`}>
              Перейти к партнеру
            </Link>
          </Button>
        </DialogButtonsRow>
      )} */}
    </Dialog>
  );
}

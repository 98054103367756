import styled from 'styled-components';
import {InputHTMLAttributes} from 'react';
import {Link} from 'react-router-dom';
import {border, breakpoint, color} from '../../../../theme';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  filled: boolean;
}

export const ButtonContainer = styled.div`
  margin: 15px 0;
  display: flex;
  flex-direction: column;

  > button {
    :first-child {
      margin-bottom: 10px;
    }
  }

  @media (min-width: ${breakpoint.tablet}) {
    flex-direction: row;

    > button {
      :first-child {
        margin-right: 5px;
        margin-bottom: 0;
      }

      :last-child {
        margin-left: 5px;
      }
    }
  }
`;

export const RetryContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${breakpoint.tablet}) {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 40px;
  }
`;

export const SlowpokeText = styled.div`
  text-align: center;
  margin-bottom: 14px;

  @media (min-width: ${breakpoint.tablet}) {
    margin-bottom: 0;
    margin-right: 8px;
  }
`;

export const RetryCodeButton = styled.button`
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  color: ${(p) => (p.disabled ? color.border : color.primary)};

  &:hover {
    opacity: 0.8;
  }

  @media (min-width: ${breakpoint.tablet}) {
    text-align: left;
  }
`;

export const CodeFormInputsGroup = styled.div`
  display: flex;
  font-size: 2.5rem;
  margin-bottom: 1em;
  flex-wrap: wrap;
`;

export const CodeFormInput = styled.input`
  font-size: 1em;
  display: block;
  text-align: center;
  padding: 0;
  box-shadow: none;
  height: 3.5em;
  width: 2em;
  border-width: 1px;
  border-style: solid;
  border-radius: ${border.radius};
  outline: none;
  &:not(last-child) {
    margin-right: auto;
  }
  border-color: ${({filled}: Props) => (filled ? color.link : '#c0c4cc')};
`;

export const CodeFromFooter = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  @media (min-width: ${breakpoint.tablet}) {
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
  }
`;

export const CodeFromBack = styled.div`
  color: ${color.link};
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 700;
  margin-top: 2rem;
  &:hover {
    text-decoration: underline;
  }
  @media (min-width: ${breakpoint.tablet}) {
    margin-top: 0;
  }
`;

export const CodeFromSkip = styled(Link)`
  color: ${color.link};
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 700;
  margin-top: 2rem;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  @media (min-width: ${breakpoint.tablet}) {
    margin-top: 0;
  }
`;

export const CodeFromFooterInfo = styled.div`
  color: #838a99;
  font-size: 1.8rem;
  span {
    color: #ff6c01;
  }
`;

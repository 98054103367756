import styled from 'styled-components';
import {breakpoint, color, font} from '../../../theme';

export const FooterRoot = styled.footer`
  display: block;
  padding: 3rem 0;
`;

export const FooterInner = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
  width: 100%;
  @media (min-width: ${breakpoint.tablet}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const FooterItems = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-bottom: 3rem;
  @media (min-width: ${breakpoint.tablet}) {
    flex-direction: row;
    margin-left: auto;
    margin-bottom: 0;
    width: 50%;
  }
`;

export const FooterItem = styled.a`
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  color: #4164e3;
  margin-bottom: 3rem;
  @media (min-width: ${breakpoint.tablet}) {
    margin-bottom: 0;
  }
`;

export const FooterItemIcon = styled.div<{name?: string}>`
  display: block;
  margin-right: 2rem;
  background: ${(p) => (p.name === 'telegram' ? '#34abe3' : color.primary)};
  position: relative;
  width: 4rem;
  height: 4rem;
  border-radius: 1.5rem;
  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 55%;
  }
`;

export const FooterItemSubName = styled.div`
  color: ${color.liteGrey};
  font-size: 1.5rem;
  font-family: ${font.family.secondary};
  font-weight: 400;
`;

export const FooterItemName = styled.div`
  font-size: 2.2rem;
  white-space: pre-line;
  font-weight: 700;
  text-transform: uppercase;
`;

export const FooterLogo = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
`;

export const FooterLogoIcon = styled.img`
  display: block;
  margin-right: 1em;
  width: 4rem;
  height: auto;
`;

export const FooterLogoName = styled.div`
  font-size: 1.5rem;
  color: #4164e3;
  text-transform: uppercase;
`;

import React, { useState, useEffect, useCallback } from "react";
import i18n from "i18next";
import { ReactComponent as DeductionIcon } from "assets/svg/deduction-list-icon.svg";
import { Notification } from "types/notification";
import { fetchNotifications } from "api";
import moment from "moment";
import { useTranslation } from "react-i18next";

function History() {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { t } = useTranslation();

  async function getList() {
    const resp = await fetchNotifications({ page: 1, limit: 7 });

    if (resp) {
      setNotifications(resp.items.splice(0, 6));
    }
  }

  useEffect(() => {
    getList();
  }, []);

  const handleLanguageChanged = useCallback(() => {
    getList();
  }, []);

  useEffect(() => {
    i18n.on("languageChanged", handleLanguageChanged);

    return () => {
      i18n.off("languageChanged", handleLanguageChanged);
    };
  }, [handleLanguageChanged]);

  return (
    <div className="p-8 px-12 ">
      <div className="text-[24px]">{t("Последние события")}</div>
      <div className="leading-[36px]">
        {notifications?.length === 0 && (
          <div className="text-[18px] text-slate-400 font-['Fira_sans'] pt-64 text-center">
            {t("Список уведомлений пуст")}
          </div>
        )}
        {!!notifications?.length &&
          notifications?.length > 0 &&
          notifications.map((item) => (
            <HistoryItem key={item.id} item={item} />
          ))}
      </div>
    </div>
  );
}

function HistoryItem({ item }: { item: Notification }) {
  return (
    <div className="flex gap-4 text-[14px] py-8 border-b font-['Fira_sans']">
      <div className="w-1/12 flex justify-center flex-col">
        <div className="w-[32px] h-[32px] bg-green-400 grid place-content-center rounded-xl">
          <DeductionIcon />
        </div>
      </div>
      <div className="w-2/12 text-slate-400 flex justify-center flex-col">
        {item.createdAt
          ? moment(item.createdAt).format("DD.MM.yyy / hh:mm")
          : "-"}
      </div>
      <div className="w-9/12 flex justify-center flex-col leading-[18px]">
        {item.sentMessage}
      </div>
    </div>
  );
}

export default History;

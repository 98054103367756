import {combine, createEffect, createEvent, createStore} from 'effector';
import {createGate} from 'effector-react';
import {AttachedEmail, DetachedEmail, Identifier, ProfileState, ICounts} from 'types/profile';
import {RestError} from '../../../core/errors';

export const ProfileGate = createGate();
export const CountGate = createGate();
export const AddPhoneGate = createGate();
export const EmailVerifyGate = createGate<{query: string}>();
export const AddEmailGate = createGate();
export const DeleteEmailGate = createGate();

export const fetchIdentifiersFx = createEffect<void, Identifier[]>();
export const fetchAmountFx = createEffect<void, ICounts>();
export const attachEmailFx = createEffect<AttachedEmail, void>();
export const detachEmailFx = createEffect<DetachedEmail, void>();
export const detachPhoneFx = createEffect<DetachedEmail, void>();
export const verifyEmailFx = createEffect<
  {query: string},
  {
    status: string;
    extraMeta: string;
    externalSystemType: number;
    redirectOnConfirmationLink: string;
  },
  RestError
>();
export const sendAdditionalPhoneFx = createEffect<
  {phone: string; token: string},
  {phone: string; codeExpiredIn: string}
>();
export const verifyAdditionalPhoneFx = createEffect<
  {phone: string; code: string; receiptExpirationDate: string},
  {status: string}
>();

export const addEmailDialogOpen = createEvent();
export const addEmailDialogClose = createEvent();
export const sendAdditionalPhone = createEvent<{phone: string; token: string}>();
export const verifyAdditionalPhone = createEvent<{
  phone: string;
  code: string;
  receiptExpirationDate: string;
}>();

export const detachEmailDialogOpen = createEvent<DetachedEmail>();
export const detachEmailDialogClose = createEvent();

export const isAddEmailDialog$ = createStore<boolean>(false);

export const confirmEmailDialogOpen = createEvent();
export const confirmEmailDialogClose = createEvent();
export const updateCounts = createEvent();

export const attachEmail = createEvent<AttachedEmail | null>();

export const isConfirmEmailDialog$ = createStore<boolean>(false);

export const emails$ = createStore<Identifier[]>([]);
export const phones$ = createStore<Identifier[]>([]);
export const counts$ = createStore<ICounts>({});
export const extraMeta$ = createStore<string | null>(null);
export const partnerRedirectLink$ = createStore<string | null>(null);

export const attachedEmail$ = createStore<AttachedEmail | null>(null);
export const detachedEmail$ = createStore<DetachedEmail | null>(null);

export const isLogoutDialog$ = createStore<boolean>(false);
export const logoutDialogClose = createEvent();

export const emailErrorMessage$ = createStore<string>('');

export const addPhoneErrorMessage$ = createStore<string | null>(null);
export const verifyPhoneErrorMessage$ = createStore<string | null>(null);
export const isCodeSended$ = createStore<boolean>(false);
export const isPhoneAdded$ = createStore<boolean>(false);

export const profileState$ = combine<ProfileState>({
  emails: emails$,
  phones: phones$,
  counts: counts$,
  isConfirmEmailDialog: isConfirmEmailDialog$,
  attachPending: attachEmailFx.pending,
  loading: fetchIdentifiersFx.pending,
  isAddEmailDialog: isAddEmailDialog$,
  isDetachEmailDialogOpen: detachedEmail$.map((detachedEmail) => !!detachedEmail),
});

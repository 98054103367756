import styled from "styled-components";
import { font } from "../../../../theme";

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 4rem;
  margin-bottom: 1rem;
  color: #222;
`;
export const Link = styled.a`
  font-size: 1.6rem;
  padding: 1rem 4rem 0;
`;
export const Tabs = styled.div`
  display: flex;
  margin: 1.3rem auto 0;
  border: 1px solid #d3d5db;
  border-radius: 12px;
  cursor: pointer;
`;
export const Tab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 3.6rem;
  padding: 0 2.7rem;
  background: #fff;
  color: #687591;
  font-family: Fira Sans, sans-serif;
  font-size: 1.6rem;

  &:first-child {
    border-radius: 12px 0 0 12px;
  }

  &:last-child {
    border-radius: 0 12px 12px 0;
  }

  &.active {
    background: #00a0e3;
    color: #fff;
  }
`;
export const Message = styled.div<{ fontSize?: string }>`
  margin: 0.5rem 0;
  padding: 0 4rem;
  font-size: ${({ fontSize }) => fontSize || "1.8rem"};
  font-family: ${font.family.secondary};
  color: #222;
  text-align: left;
  line-height: 1.4em;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }
`;

import React, { useEffect, useState } from "react";
import { useStore } from "effector-react";
import {
  LeftSideBarContainer,
  LeftSideBarItem,
  LeftSideBarItemName,
  LeftSideBarNotificationMark,
  LeftSideBarSocial,
} from "./styled";
// icons
import iconHome from "../../../assets/svg/home.svg";
import iconHomeActive from "../../../assets/svg/home-active.svg";
import iconNotify from "../../../assets/svg/bell.svg";
import iconNotifyActive from "../../../assets/svg/bell-active.svg";
import iconLottery from "../../../assets/svg/star.svg";
import iconLotteryActive from "../../../assets/svg/star-active.svg";
import iconAffiliate from "../../../assets/svg/affiliates.svg";
import iconAffiliateActive from "../../../assets/svg/affiliates-active.svg";
import iconReceipt from "../../../assets/svg/receipts.svg";
import iconReceiptActive from "../../../assets/svg/receipts-active.svg";
import iconQr from "../../../assets/svg/ic-qr.svg";
import iconQrActive from "../../../assets/svg/ic-qr--active.svg";
import iconUser from "../../../assets/svg/ic-nav-user.svg";
import iconUserActive from "../../../assets/svg/ic-nav-user--active.svg";
import iconNotifications from "../../../assets/svg/notifications-menu.svg";
import iconNotificationsActive from "../../../assets/svg/notifications-menu-active.svg";
import { profileState$, counts$ } from "models/settings/profile";
import { useTranslation } from "react-i18next";

declare global {
  interface Window {
    qrScanner: {
      postMessage: Function;
    };
  }
}

export function LeftSideBar() {
  const { t } = useTranslation();
  const [barHeight, setBarHeight] = useState(0);
  const { emails } = useStore(profileState$);
  const { unreadNotification, unreadLotteryWinCount } = useStore(counts$);

  function getBarHeight() {
    const header = document.getElementById("header")?.offsetHeight || 0;
    setBarHeight(window.innerHeight - header);
  }

  useEffect(() => {
    setTimeout(() => {
      getBarHeight();
    }, 0);
  }, [emails]);

  const items = [
    {
      name: t("Главная"),
      icon: iconHome,
      iconActive: iconHomeActive,
      link: "/",
      mobile: false,
      id: "learning-show--1",
    },
    {
      name: t("Уведомления"),
      icon: iconNotifications,
      iconActive: iconNotificationsActive,
      link: "/notifications",
      mobile: false,
      id: "learning-show--1",
    },
    {
      name: t("Ваши чеки"),
      icon: iconReceipt,
      iconActive: iconReceiptActive,
      link: "/receipts",
      mobile: false,
      id: "learning-show--2",
    },
    {
      name: t("Розыгрыши"),
      icon: iconLottery,
      iconActive: iconLotteryActive,
      link:
        unreadLotteryWinCount && unreadLotteryWinCount > 0
          ? "/lotery/win"
          : "/lotery",
      mobile: false,
      id: "learning-show--3",
    },
    {
      name: t("Партнеры"),
      icon: iconAffiliate,
      iconActive: iconAffiliateActive,
      link: "/partners",
      mobile: false,
      id: "learning-show--0-mob",
      className: "learning",
    },
    {
      name: "QR",
      icon: iconQr,
      iconActive: iconQrActive,
      link: "/private-qr",
      mobile: true,
    },
    {
      name: t("Пользователь"),
      icon: iconUser,
      iconActive: iconUserActive,
      link: "/account",
      mobile: true,
      id: "learning-show--0-mob",
      className: "learning",
    },
  ];

  return (
    <LeftSideBarContainer style={{ minHeight: `calc(${barHeight}px - 3rem)` }}>
      {items.map((item, index) => (
        <LeftSideBarItem
          exact
          to={item.link}
          $logo={item.icon}
          $logoActive={item.iconActive}
          $mobile={item.mobile}
          $index={index}
          key={item.name}
          onClick={(e) => {
            if (window.qrScanner && item.link === "/private-qr") {
              e.preventDefault();
              window.qrScanner.postMessage("qrScanner action");
            }
          }}
          id={item.id || ""}
        >
          {!!unreadNotification && item.link === "/notifications" && (
            <LeftSideBarNotificationMark>
              {unreadNotification}
            </LeftSideBarNotificationMark>
          )}
          {!!unreadLotteryWinCount &&
            (item.link === "/lotery/win" || item.link === "/lotery") && (
              <LeftSideBarNotificationMark>
                {unreadLotteryWinCount}
              </LeftSideBarNotificationMark>
            )}
          <LeftSideBarItemName>{item.name}</LeftSideBarItemName>
        </LeftSideBarItem>
      ))}
      <LeftSideBarSocial
        type="telegram"
        href="https://t.me/Cheki_FNS_bot"
        target="_blank"
      >
        <LeftSideBarItemName>Телеграм</LeftSideBarItemName>
      </LeftSideBarSocial>
    </LeftSideBarContainer>
  );
}

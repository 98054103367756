import React from "react";
import { HeaderToast } from "./styled";
import { useTranslation } from "react-i18next";

export function BindEmailToast() {
  const { t } = useTranslation();
  return (
    <HeaderToast>
      <span>
        {t("Link your email to your personal account and get more receipts")}
      </span>
    </HeaderToast>
  );
}

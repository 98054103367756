import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { color } from "../../../../../theme";

export const StyledContentNavBar = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
  margin-top: -50px;
  font-size: 2rem;
`;

export const ContentNavBarItem = styled(NavLink)`
  background: #ffffff;
  text-decoration: none;
  padding: 12px 20px;
  color: #00a0e3;
  text-decoration: none;
  &:first-child {
    border-radius: 10px 0 0 10px;
  }
  &:last-child {
    border-radius: 0 10px 10px 0;
  }
  &.active {
    background: #00a0e3;
    color: #ffffff;
  }
`;
export const ContentNavBarItemDisabled = styled.span`
  background: #ffffff;
  text-decoration: none;
  padding: 12px 20px;
  color: #00a0e3;
  text-decoration: none;
  opacity: 0.6;
  cursor: not-allowed;
  &:first-child {
    border-radius: 10px 0 0 10px;
  }
  &:last-child {
    border-radius: 0 10px 10px 0;
  }
  &.active {
    background: #00a0e3;
    color: #ffffff;
  }
`;

export const ContentNavBarDivider = styled.span``;

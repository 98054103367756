import styled from 'styled-components';

export const HeaderToast = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #547A8F;
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 1.6rem;
  padding: 0.8em;
  box-shadow: 0 24px 60px rgba(66, 101, 227, 0.2), 0 16px 40px rgba(66, 101, 227, 0.2),
    0 4px 15px rgba(66, 101, 227, 0.2), 0 0 1px rgba(8, 23, 78, 0.3);
`;

export const HeaderToastClose = styled.div`
  position: absolute;
  right: 1em;
  top: 1em;
  width: 1em;
  height: 1em;
  background: red;
`;

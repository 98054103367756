import React, { useState, Fragment } from "react";
import { Dialog } from "components/ui-kit/Dialog";
import { CloseDialogIcon } from "components/ui-kit/Dialog/styled";
import { ReactComponent as CloseIcon } from "assets/svg/ic-close.svg";
import "react-custom-scroll/dist/customScroll.css";
import { Button } from "components/ui-kit/Button";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import { counts$ } from "models/settings/profile";
import { useStore } from "effector-react";
import { fetchPayout } from "api";
import { useTranslation } from "react-i18next";

interface Props {
  onDismiss: Function;
}



export default function WithdrawalModal({ onDismiss }: Props) {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState({
    id: "",
    name: t("Выберите способ вывода"),
  });
  const WITHDRAWAL_TYPES = [
    { id: "CARD", name: t("На банковскую карту") },
    { id: "PHONE", name: t("На счет мобильного телефона") },
    { id: "BANK_ACCOUNT", name: t("На банковский счет") },
  ];
  const [sum, setSum] = useState<string | undefined>();
  const counts = useStore(counts$);

  const handleSubmit = () => {
    fetchPayout({
      operationAmount: sum ? Number(sum) * 100 : 0,
      availableAmount: counts?.balance || 0,
      payoutAccount: "123123",
      payoutType: selectedType.id,
    })
      .then((res) => {
        toast.success("Заявка на вывод средств успешно отправлена");
        onDismiss();
      })
      .catch((err) => {
        toast.error("Ошибка при отправке заявки на вывод средств");
      });
  };

  return (
    <Dialog onDismiss={onDismiss} ariaLabel="notification-modal">
      <div className="w-full flex justify-between">
        <div className="text-7xl uppercase font-['DNC']">{t("Вывод средств")}</div>
        <div>
          <CloseDialogIcon source={CloseIcon} onClick={() => onDismiss()} />
        </div>
      </div>
      <div className="py-12">
        <div className="text-[14px]">
          {t("Выберите способ вывода средств и укажите сумму перевода")}
        </div>
        <div className="pt-16">
          <Listbox value={selectedType} onChange={setSelectedType}>
            <div className="relative mt-1 mb-10 text-[14px]">
              <Listbox.Button className="relative w-full cursor-pointer rounded-3xl bg-slate-100 p-14 text-left focus:outline-none focus-visible:border-[#00A0E3] focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
                {selectedType.id && (
                  <span className="block truncate text-gray-900">
                    {selectedType.name}
                  </span>
                )}
                {!selectedType.id && (
                  <span className="block truncate">{selectedType.name}</span>
                )}
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-8">
                  <ChevronUpDownIcon
                    className="h-[20px] w-[20px] text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute mt-1 w-full overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {WITHDRAWAL_TYPES.map((person, personIdx) => (
                    <Listbox.Option
                      key={personIdx}
                      className={({ active }) =>
                        `relative cursor-pointer select-none p-8 pr-4 ${
                          active ? "bg-[#00A0E3] text-white" : "text-gray-900"
                        }`
                      }
                      value={person}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`text-[14px] block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {person.name}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
        <div>
          <input
            placeholder={t("Сумма")}
            className="h-[72px] border-none w-full text-[14px] p-8 px-12 bg-slate-100 rounded-3xl text-gray-900"
            value={sum}
            onChange={(e) => {
                setSum(e?.target?.value?.replace(/[^0-9]/g, ""));
            }}
          />
        </div>
        <div className="pt-8">
          <div className="text-[18px] pt-10 font-['DNC']">{t("Личный счет")}:</div>
          <div className="text-[48px] text-[#00A0E3] font-bold font-['DNC'] pb-8">
            {counts?.balance
              ? Math.floor(counts?.balance / 100).toLocaleString("ru-RU", {
                  maximumFractionDigits: 0,
                })
              : "0"}{" "}
          </div>
        </div>
      </div>
      <div>
        <Button
          className="w-full mb-14"
          theme="default"
          onClick={handleSubmit}
          disabled={!selectedType.id || !sum}
        >
          {t("Продолжить")}
        </Button>
        <Button className="w-full" theme="simple" onClick={() => onDismiss()}>
          {t("Отмена")}
        </Button>
      </div>
    </Dialog>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { ReceiptIcon, DeductionIcon } from "./styled";
import { formatDate } from "../../../utils/date";
import { Button } from "../../ui-kit/Button";
import { NotificationModal } from "./NotificationModal";
import { Notification, NotificationRequest } from "../../../types/notification";
import { fetchNotifications, markNotificationAsRead } from "../../../api";
import i18n from "i18next";
import { updateCounts } from "../../../models/settings/profile";
import { useTranslation } from "react-i18next";
import { SpinnerContainer } from "../Receipt/components/ReceiptTable/ReceiptDetails/styled";
import { Spinner } from "../../ui-kit/Spinner";
import { NoData } from "../Receipt/components/TableList/styled";

export function NotificationsTab() {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [pending, setPending] = useState(true);
  const [requestParams, setRequestParams] = useState({ page: 1 });
  const [notification, setNotification] = useState<Notification | null>(null);
  const [showModal, setShowModal] = useState(false);

  async function getList(requestParams: NotificationRequest) {
    setPending(true);
    const resp = await fetchNotifications(requestParams);
    setPending(false);

    if (resp) {
      setRequestParams(requestParams);
      setNotifications(notifications.concat(resp.items));
      setHasMore(!resp.lastPage);
    }
  }

  const readNotification = (kafkaId: string) => {
    markNotificationAsRead(kafkaId).then(() => {
      updateCounts();
      setNotifications(
        notifications.map((item) => {
          if (item.kafkaId === kafkaId) {
            item.isRead = true;
          }
          return item;
        })
      );
    });
  };

  const showMore = () => {
    getList({ page: requestParams.page + 1 });
  };

  const handleLanguageChanged = useCallback(() => {
    getList({ page: 1 });
  }, []);

  useEffect(() => {
    getList({ page: 1 });
  }, []);

  useEffect(() => {
    i18n.on("languageChanged", handleLanguageChanged);

    return () => {
      i18n.off("languageChanged", handleLanguageChanged);
    };
  }, [handleLanguageChanged]);

  return (
    <>
      {notifications.map((_notification, i) => (
        <div
          key={i}
          // isNew={_notification.isRead === false}
          onMouseEnter={() => {
            if (!_notification.isRead) {
              readNotification(_notification.kafkaId || "");
            }
          }}
          className={`w-full border-2 border-white flex bg-white rounded-3xl p-8 mb-6 ${
            _notification.isRead ? "" : "border-2 border-blue-300"
          }`}
        >
          <div className="w-full md:w-1/12 flex justify-center flex-col">
            <span>
              {_notification.notificationType === "TAX_DEDUCTION" && (
                <ReceiptIcon />
              )}
              {_notification.notificationType !== "TAX_DEDUCTION" && (
                <DeductionIcon />
              )}
            </span>
          </div>
          <div className="w-full md:w-2/12 text-slate-500 text-[14px] font-['Fira_sans'] flex justify-center flex-col">
            <span>
              {_notification?.createdAt &&
                formatDate(
                  new Date(_notification?.createdAt),
                  "dd.MM.yyyy HH:mm"
                )}
            </span>
          </div>
          <div className="w-full md:w-9/12 text-slate-900 text-[14px] leading-[17px] font-['Fira_sans'] flex justify-center flex-col">
            <span>{_notification?.sentMessage}</span>
          </div>
        </div>
      ))}
      {hasMore && (
        <Button style={{ margin: "3rem 0 5rem 0" }} onClick={showMore}>
          {t("Показать еще")}
        </Button>
      )}
      <SpinnerContainer>
        {pending && <Spinner radius="20px" />}
      </SpinnerContainer>
      {notifications.length === 0 && !pending && (
        <NoData>{t("Нет уведомлений")}</NoData>
      )}
      {showModal && notification && (
        <NotificationModal
          notification={notification}
          onDismiss={() => setShowModal(false)}
        />
      )}
    </>
  );
}

import { PageableRequestParams } from "./pagination";

export type AppealAcceptType = "QR" | "URL" | "PHOTO";
export type AppealStatusType =
  | "NEW"
  | "IN_WORK"
  | "SENT_PENALTY"
  | "SENT_WARNING"
  | "CLOSED";
export type AppealTextType = "Невыдача чека" | string;

export const AppealStatusTypeMapping: Partial<Record<AppealStatusType, any>> = {
  NEW: "#00A0E3",
  IN_WORK: "#44CC7A",
  CLOSED: "#999999",
};

export const AppealAcceptTypeMapping: Record<AppealAcceptType, any> = {
  QR: "QR code",
  URL: "URL address",
  PHOTO: "By photo",
};

export interface IAppeal {
  id: string | number;
  createdDate: string;
  daysToExpire: number;
  appealText: AppealTextType;
  status: AppealStatusType;
  acceptType: AppealAcceptType;
  tin: string;
  url: string;
  coords: string;
  address: string;
  sellerName: string;
  photos: string[];
}

export interface AppealRequestParams extends PageableRequestParams {
  text: string;
  status: AppealStatusType;
  date: string;
  acceptType: AppealAcceptType;
}

export const color = {
  // new
  link: "#01A0E3",
  secondary: "#01A0E3",
  grey: "#687591",
  liteGrey: "#9ea3ad",
  orange: "#FF6C01",
  // old
  primary: "#01A0E3",
  muted: "#B7E5F9",
  danger: "#e1253b",
  success: "#1bb37a",
  white: "#fff",
  text: "#4a4a4a",
  border: "#bababa",
  borderError: "#f30b27",
  transparent: "transparent",
};

export const font = {
  family: {
    primary: "DNC, sans-serif",
    secondary: "Fira Sans, sans-serif",
  },
};

export const border = {
  radius: "1.8rem",
};

export const transition = {
  duration: "200ms",
  func: "ease",
};

export const breakpoint = {
  phone: "500px",
  tablet: "768px",
  desktop: "1170px",
  hd: "1440px",
};

export const visuallyHidden = `
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;

    white-space: nowrap;

    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
`;

export const textEllipsis = `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const boxShadow = "0 2px 4px rgba(108, 108, 108, 0.2)";

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useGate, useStore } from "effector-react";
import { toast } from "react-toastify";
import {
  LogoTitle,
  Inner,
  LogoContainer,
  LogoImage,
  NotificationContainer,
  NotificationIconContainer,
  PageTitle,
  HeaderIcons,
  HeaderUser,
  HeaderLogout,
  HeaderCalendar,
  SearchContainer,
  BalanceContainer,
  LoteryContainer,
} from "./styled";
import { MaxWidthContainer } from "../../ui-kit/MaxWidthContainer";
import { ReactComponent as ReceiptIcon } from "../../../assets/svg/list_2.svg";
import { ReactComponent as OutcomeIcon } from "../../../assets/svg/outcome-icon.svg";
import { ReactComponent as LoteryWinIcon } from "../../../assets/svg/lotery-win.svg";
import logoSrc from "../../../assets/images/logo-header.svg";
import { ReactComponent as RussianFlag } from "assets/svg/russia.svg";
import { Icon } from "../../ui-kit/Icon";
import { Input } from "../../ui-kit/Input";
import WithdrawalModal from "components/pages/Dashboard/components/WithdrawalModal";

import {
  HeaderGate,
  lastReceiptData$,
  showNewReceipts,
} from "../../../models/header";
import { filters$, queryChanged } from "../../../models/receipt";
import { UserInfoModal } from "./UserInfoModal";
import { ExitDialog } from "./ExitDialog";
import { logout, isAuth$ } from "../../../models/auth";
import { CalendarDialog } from "./CalendarDialog";
import { BindEmailToast } from "./BindEmailToast";
import { BlockedToast } from "./BlockedToast";
import { profileState$, counts$ } from "models/settings/profile";
import { isMobile } from "../../../utils/isMobile";
import { useTranslation } from "react-i18next";
import { useDetectClickOutside } from "react-detect-click-outside";
import { fetchLocale } from "api";

const TOAST_NEW_RECEIPT_ID = "hasNewReceipt";

export function Header() {
  const { t } = useTranslation();
  const [showUserInfoModal, setShowUserInfoModal] = useState(false);
  const [isExitModalShow, setIsExitModalShow] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  useGate(HeaderGate, { history });
  const { pathname } = useLocation();
  const isLogin = pathname === "/login";
  const isReceipts = pathname === "/receipts";
  const isVerify = new RegExp("/verify").test(pathname);
  const { hasNew: hasNewReceipts, key: lastReceiptKey } =
    useStore(lastReceiptData$);
  const { query } = useStore(filters$);
  const { emails } = useStore(profileState$);
  const { balance, unreadLotteryWinCount, isBlocked } = useStore(counts$);
  const isAuth = useStore(isAuth$);
  const IS_MOBILE = isMobile();

  useEffect(() => {
    if (emails.length) {
      let confirmedEmailExist = false;
      emails.forEach((email) => {
        if (!confirmedEmailExist) {
          if (email.confirmed) confirmedEmailExist = true;
        }
      });
      setShowToast(!confirmedEmailExist);
    } else {
      // TODO email toast
      setShowToast(true);
    }
  }, [emails]);

  useEffect(() => {
    const toastIsActive = toast.isActive(TOAST_NEW_RECEIPT_ID);
    if (!hasNewReceipts) {
      toastIsActive && toast.dismiss(TOAST_NEW_RECEIPT_ID);
      return;
    }
    if (!toastIsActive) {
      toast.info(
        <NotificationContainer>
          <NotificationIconContainer>
            <Icon source={ReceiptIcon} />
          </NotificationIconContainer>
          <span>Доступны новые чеки</span>
        </NotificationContainer>,
        {
          position: "top-center",
          autoClose: 20000,
          toastId: TOAST_NEW_RECEIPT_ID,
          onClick: () => showNewReceipts(),
        }
      );
    }
  }, [lastReceiptKey, hasNewReceipts]);

  let pageTitle;
  switch (pathname) {
    case "/":
      pageTitle = t("Главная");
      break;
    case "/lotery/win":
      pageTitle = t("Призы");
      break;
    case "/notifications":
      pageTitle = t("Уведомления");
      break;
    case "/lotery":
      pageTitle = t("Розыгрыши");
      break;
    case "/receipts":
      pageTitle = t("Чеки");
      break;
    case "/account":
      pageTitle = t("Профиль");
      break;
    case "/payments":
      pageTitle = t("Начисления");
      break;
    case "/payments/outcome":
      pageTitle = t("Вывод средств");
      break;
    default:
      pageTitle = "";
  }

  function logoutAction() {
    window.ym(85973717, "reachGoal", "logout");
    setIsExitModalShow(false);
    logout(false);
  }

  return (
    <>
      <MaxWidthContainer id="header">
        {!isLogin && !isVerify && (
          <>
            {showToast && <BindEmailToast />}
            {isBlocked && <BlockedToast />}
            {isExitModalShow && (
              <ExitDialog
                onSubmit={logoutAction}
                onDismiss={() => setIsExitModalShow(false)}
              />
            )}
            {showCalendar && (
              <CalendarDialog
                onSubmit={() => setShowCalendar(false)}
                onDismiss={() => setShowCalendar(false)}
              />
            )}
            {showUserInfoModal && (
              <UserInfoModal onDismiss={() => setShowUserInfoModal(false)} />
            )}
          </>
        )}
        <Inner
          isLogin={isLogin}
          style={{
            paddingTop:
              (showToast && !isLogin && !isVerify) || isBlocked ? "3rem" : "0",
          }}
        >
          <LogoContainer to="/" $isLogin={isLogin || isVerify}>
            <LogoImage
              isLogin={isLogin || isVerify}
              src={logoSrc}
              alt="логотип ЛИЧНЫЙ КАБИНЕТ ПОКУПАТЕЛЯ"
            />
            {(isLogin || isVerify) && (
              <>
                <LogoTitle>
                  {t("Личный кабинет")}
                  <br />
                  {t("покупателя")}
                </LogoTitle>
              </>
            )}
          </LogoContainer>
          {!isLogin && !isVerify && (
            <>
              <PageTitle>{pageTitle}</PageTitle>
              <HeaderIcons>
                {isReceipts && !IS_MOBILE && (
                  <SearchContainer>
                    <div style={{ width: 600, padding: "0 15px" }}>
                      <Input
                        value={query || ""}
                        onChange={(e) => queryChanged(e.target.value)}
                        placeholder={t("Поиск")}
                      />
                    </div>
                    <HeaderCalendar onClick={() => setShowCalendar(true)} />
                  </SearchContainer>
                )}
                {!!unreadLotteryWinCount && unreadLotteryWinCount > 0 && (
                  <LoteryContainer onClick={() => history.push("/lotery/win")}>
                    <div className="main-container">
                      <div className="title">{t("Лотерея")}</div>
                      <div className="description">{t("Вы выиграли")}</div>
                    </div>
                    <div className="button-win">
                      <LoteryWinIcon className="icon-win" />
                    </div>
                  </LoteryContainer>
                )}

                <BalanceContainer>
                  <div className="main-container">
                    <div className="title">{t("Личный счет")}</div>
                    <div
                      className="balance cursor-poiner"
                      onClick={() => history.push("/payments")}
                    >
                      {balance
                        ? Math.floor(balance / 100).toLocaleString("ru-RU", {
                            maximumFractionDigits: 0,
                          })
                        : "0"}{" "}
                    </div>
                  </div>
                  <div
                    className={`button-outcome ${isBlocked ? "blocked" : ""}`}
                    onClick={() => {
                      if (isBlocked) return;
                      setIsModalOpen(true);
                    }}
                  >
                    <OutcomeIcon className="icon-outcome" />
                  </div>
                </BalanceContainer>

                <LanguageSelect />

                <HeaderUser
                  id="learning-show--0"
                  onClick={() => {
                    if (isAuth) {
                      setShowUserInfoModal(!showUserInfoModal);
                    } else {
                      history.push("/login");
                    }
                  }}
                />
                <HeaderLogout onClick={() => setIsExitModalShow(true)} />
              </HeaderIcons>
            </>
          )}
        </Inner>
      </MaxWidthContainer>
      {isReceipts && IS_MOBILE && (
        <div style={{ paddingBottom: 20 }}>
          <SearchContainer>
            <Input
              value={query || ""}
              onChange={(e) => queryChanged(e.target.value)}
              placeholder={t("Поиск")}
            />
          </SearchContainer>
        </div>
      )}
      {isModalOpen && (
        <WithdrawalModal onDismiss={() => setIsModalOpen(false)} />
      )}
    </>
  );
}

function LanguageSelect() {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useDetectClickOutside({
    onTriggered: () => {
      setIsOpen(false);
    },
  });

  return (
    <div ref={ref} className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`bg-white h-[44px] w-[44px] text-[12px] text-center leaning-[46px] ml-16 rounded-[1.8rem] overflow-hidden cursor-pointer ${
          isOpen ? "" : ""
        }`}
      >
        {t("language")}
      </button>
      {isOpen && (
        <div
          className={`text-[12px] leading-[34px] text-center absolute top-[54px] bg-white right-[-50px] w-[144px] z-[100] rounded-[1.8rem] py-[20px] shadow-lg ${
            isOpen ? "block" : "none"
          }`}
        >
          <div
            onClick={() => {
              fetchLocale('RU', () => {
                i18n.changeLanguage("ru");
                setIsOpen(false);
              });
            }}
            className="cursor-pointer hover:bg-blue-100"
          >
            {t("language_ru")}
          </div>
          <div
            onClick={() => {
              fetchLocale('EN', () => {
                i18n.changeLanguage("en");
                setIsOpen(false)
              });
            }}
            className="cursor-pointer hover:bg-blue-100"
          >
            {t("language_en")}
          </div>
        </div>
      )}
    </div>
  );
}

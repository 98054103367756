import { apiClient } from "../core/apiClient";
import {
  NotificationsCountResponse,
  NotificationRequest,
  NotificationResponse,
} from "../types/notification";

export async function fetchNotifications(params: NotificationRequest) {
  try {
    return (
      await apiClient.get("/api/v1/notifications", {
        params: { ...params, sort: "created_at", order: "DESC" },
      })
    ).data as NotificationResponse;
  } catch (e) {
    console.error(e);
  }
}

export async function fetchNotificationsCount() {
  try {
    return (await apiClient.get("/api/v1/profile/info"))
      .data as NotificationsCountResponse;
  } catch (e) {
    console.error(e.code);
  }
}

export async function markNotificationAsRead(id: string) {
  try {
    await apiClient.put(`/api/v1/notifications?kafkaId=${id}`);
  } catch (e) {
    console.error(e);
  }
}
